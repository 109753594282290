<template>
  <div :class="addClass">
    <div :class="formClass">
      <label v-if="formLabel" class="form__label form__label--block">{{ getTranslation(formLabel) }} <span v-if="required" class="required">*</span></label>
      <span v-if="description" class="text text-muted">{{ getTranslation(description) }}</span>
      <v-select
          ref="serviceSelect"
          v-model="item_id"
          :options="items"
          :reduce="option=>option.id"
          @search:blur="!item_id ? customValidator.item_id = false : customValidator.item_id = true"
          :class="{ 'is-invalid': !customValidator.item_id && required}"
          :placeholder="getTranslation('selectFromList')"
          label="label"
      />
      <div v-if="required && !customValidator.item_id" class="invalid-feedback">{{ getTranslation('requiredField') }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ServiceOptionComponent",
  props: {
    editItemId: {
      required: false
    },
    required: {
      type: Boolean,
      default: false
    },
    description: {
      type: String
    },
    addClass: String,
    formLabel: {
      type: String,
      default: 'serviceOptions'
    },
    formClass: {
      type: String,
      default: 'form-group'
    },
    byLand: Boolean,
    full: Boolean,
  },
  data() {
    return {
      items: [],
      item_id: '',
      timer: null,
      showSelect: false,
      customValidator: {
        item_id: true,
      }
    }
  },
  watch: {
    'item_id'(id) {

      this.$emit('input', id);

      if ( !id ) {
        this.fetchItems();
      }
    }
  },
  created() {
    this.fetchItems().then(() => {
      if ( this.editItemId )
        this.item_id = this.editItemId;
    })
  },
  methods: {

    fetchItems() {
      return this.axios.get(this.$urls.application.serviceOptions.url).then(response => {

        if ( this.full )
          this.items = response.data.data.map(item => {

            item.label = this.getTranslation(item.airText )+ ' (' + this.getTranslation(item.landText) + ')';

            return item;
          });

        else if ( this.byLand )
          this.items = response.data.data.map(item => {

            item.label = this.getTranslation(item.landText);

            return item;
          });
        else
          this.items = response.data.data.map(item => {

            item.label = this.getTranslation(item.airText );

            return item;
          });

      }).catch(()=>{});
    },
    // We will call this method from parent component for check validity
    checkValidity() {
      if ( !this.item_id ) {
        this.customValidator.item_id = false;
        return false;
      }

      return true;
    }
  }
}
</script>

<style scoped>

</style>